import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { DECIMAL_1, DECIMAL_2 } from "presentation/utils/numberUtil";

const CONTAINER_CONSTANT = ChargeDetailConstant.Charge;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
let numberFieldList:string[] = [];

export const INITIAL_CHARGE_DETAIL_COL_DEF: any[] = [
    {
        headerName: CONTAINER_CONSTANT.STATE,
        field: 'dtlState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.SEQ,
        field: 'seq',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.CNTR_NO,
        field: 'cntrNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SUB_CHARGE_TYPE,
        field: 'subChargeType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGE_ON_COMPANY,
        field: 'chargeOnCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.ADJ_TYPE,
        field: 'adjType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.TARIFF_CODE,
        field: 'tariffCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.TARIFF_TYPE,
        field: 'tariffType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.SIZE,
        field: 'size',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 90,
    },
    {
        headerName: CONTAINER_CONSTANT.TYPE,
        field: 'cntrType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 90,
    },
    {
        headerName: CONTAINER_CONSTANT.CNTR_STATUS,
        field: 'status',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.BILLING_TYPE,
        field: 'billingType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.OPS_LINE,
        field: 'opsLine',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.FORWARDER_CODE,
        field: 'forwarderCode1',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 170,
    },
    {
        headerName: CONTAINER_CONSTANT.FROM_TML,
        field: 'fromTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.TO_TML,
        field: 'toTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 110,
    },
    {
        headerName: CONTAINER_CONSTANT.SL_VESSEL_CODE,
        field: 'slVesselCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.SL_IB_VOYAGE_CODE,
        field: 'slIbVoyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.SL_OB_VOYAGE_CODE,
        field: 'slObVoyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.ALTERABLE_CHARGE_ON_COMPANY,
        field: 'alterableChargeOnCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 280,
    },
    {
        headerName: CONTAINER_CONSTANT.ALTERABLE_BILL_TO_COMPANY,
        field: 'alterableBillToCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 260,

    },
    {
        headerName: CONTAINER_CONSTANT.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.CONSORTIUM_CODE_ARRIVAL,
        field: 'arrivalConsortiumCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_CODE_ARRIVAL,
        field: 'arrivalVesselCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.VOYAGE_CODE_ARRIVAL,
        field: 'arrivalVoyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.SERVICE_CODE_ARRIVAL,
        field: 'arrivalServiceCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_NAME_ARRIVAL,
        field: 'arrivalVesselName',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.CONSORTIUM_CODE_DEPARTURE,
        field: 'departureConsortiumCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 280,
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_CODE_DEPARTURE,
        field: 'departureVesselCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.VOYAGE_CODE_DEPARTURE,
        field: 'departureVoyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.SERVICE_CODE_DEPARTURE,
        field: 'departureServiceCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_NAME_DEPARTURE,
        field: 'departureVesselName',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.BUNDLE_IND,
        field: 'bundleInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 140,
    },
    {
        headerName: CONTAINER_CONSTANT.BUNDLE_NUMBER,
        field: 'bundleNumber',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.MODALITY_OF_ARRIVAL,
        field: 'modalityOfArrival',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: '200px'
    },
    {
        headerName: CONTAINER_CONSTANT.MODALITY_OF_DEPARTURE,
        field: 'modalityOfDeparture',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: '210px'
    },
    {
        headerName: CONTAINER_CONSTANT.GATE_IN,
        field: 'gateInDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: CONTAINER_CONSTANT.IN_YARD_DATE,
        field: 'inYardDay',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_1,
        cellStyle: { textAlign: 'right' },
        width: 140,
    },
    {
        headerName: CONTAINER_CONSTANT.SPECIAL_HANDLING_CODE,
        field: 'specialHandlingCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGE_IND,
        field: 'chargeInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 140,
    },
    {
        headerName: CONTAINER_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.POO,
        field: 'poo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.POL,
        field: 'pol',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.POD,
        field: 'pod',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.FD,
        field: 'fd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.DEPARTURE_DATE_TIME,
        field: 'depDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.ARRIVAL_DATE_TIME,
        field: 'arrDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.RECEIVE_DATE_TIME,
        field: 'receiveDateTime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.DELIVERY_DATE_TIME,
        field: 'deliveryDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.HANDLING_DATE_TIME,
        field: 'handlingDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_NAME,
        field: 'vesselName',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.TS_LEG_CHARGE_IND,
        field: 'tsLegChargeInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.MANUAL_TS_LEG_CHARGE_IND,
        field: 'manualTsLegChargeInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.EXCEPTION_MESSAGE,
        field: 'exceptionMsg',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CONTAINER_CONSTANT.BARGE_JOB_ID,
        field: 'bargeJobId',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.VOYAGE_OPERATING_TML,
        field: 'voyageOperatingTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.VOYAGE_SERVICE_CODE,
        field: 'voyageServiceCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.SOA,
        field: 'soa',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.QTY,
        field: 'qty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.RATE,
        field: 'rate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.DIRECT_IND,
        field: 'directInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.SPECIAL_IND,
        field: 'specialInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 140,
    },
    {
        headerName: CONTAINER_CONSTANT.SPECIAL_HANDLING_IND,
        field: 'specialHandlingInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 220,
    },
    {
        headerName: CONTAINER_CONSTANT.EQUIPMENT_CODE,
        field: 'equipmentCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.IMCO_CODE,
        field: 'imcoCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.IMCO_CODE2,
        field: 'imcoCode2',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.IMCO_CODE3,
        field: 'imcoCode3',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.GROSS_WT,
        field: 'grossWt',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.DU_CONTENT,
        field: 'duContent',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 140,
    },
    {
        headerName: CONTAINER_CONSTANT.DG_CONTENT,
        field: 'dgContent',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 140,
    },
    {
        headerName: CONTAINER_CONSTANT.VOLTAGE,
        field: 'voltage',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.RF_TEMP,
        field: 'rfTemp',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.RF_TEMP_UNIT,
        field: 'rfTempUnit',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.PERMIT_NO,
        field: 'permitNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.REFERENCE_NO,
        field: 'reference',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.LOADING_DATE_TIME,
        field: 'loadingDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.DISCHARGE_DATE_TIME,
        field: 'dischargeDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.ORIG_RECEIVE_DATE_TIME,
        field: 'origReceivalDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 270,
    },
    {
        headerName: CONTAINER_CONSTANT.NON_CELLULAR_VESSEL_IND,
        field: 'nonCellularVesselInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 220,
    },
    {
        headerName: CONTAINER_CONSTANT.WIRE_SLING_HANDLING_IND,
        field: 'wireSlingHandlingInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 240,
    },
    {
        headerName: CONTAINER_CONSTANT.SHIFT_CODE,
        field: 'shiftCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SURCHARGE_PERCENTAGE,
        field: 'surchargePercentage',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SO_NUMBER,
        field: 'soNumber1',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 140,
    },
    {
        headerName: CONTAINER_CONSTANT.CARGO_CODE,
        field: 'cargoCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 140,
    },
    {
        headerName: CONTAINER_CONSTANT.TRAIN_ID,
        field: 'trainId',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.TRACTOR_NO,
        field: 'tractorNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.FORWARDER_REFERENCE,
        field: 'forwarderReference',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 170,
    },
    {
        headerName: CONTAINER_CONSTANT.OH_DIMEN,
        field: 'ohDimen',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.OL_DIMEN,
        field: 'olDimen',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.OW_DIMEN,
        field: 'owDimen',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.POO_GEOG_IND,
        field: 'pooGeogInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.POL_GEOG_IND,
        field: 'polGeogInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.POD_GEOG_IND,
        field: 'podGeogInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.FD_GEOG_IND,
        field: 'fdGeogInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.POO_COUNTRY,
        field: 'pooCountry',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.POL_COUNTRY,
        field: 'polCountry',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.POD_COUNTRY,
        field: 'podCountry',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.FD_COUNTRY,
        field: 'fdCountry',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SHIPMENT_TYPE,
        field: 'shipmentType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.INVOICE_LEG,
        field: 'invoiceLeg',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_LEG_TS_LEG_CHARGE_IND,
        field: 'otherLegTsLegChargeInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.REASON_CODE,
        field: 'tempaccountTmlReasonCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: 'calcColumn',
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.INVOICE_NO,
        field: 'invoiceNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.DRAFT_INVOICE_NO,
        field: 'draftInvoiceNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.DRAFT_CREDIT_NO,
        field: 'draftCreditNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.MC_NO,
        field: 'mcNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 190,
    },
    {
        headerName: CONTAINER_CONSTANT.CR_NO,
        field: 'crNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.CREDIT_NOTE_NO,
        field: 'creditNoteNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.OFF_STD_BACK_LENGTH,
        field: 'offStdBackLength',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.OFF_STD_FRONT_LENGTH,
        field: 'offStdFrontLength',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.OFF_STD_LEFT_WIDTH,
        field: 'offStdLeftWidth',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.OFF_STD_RIGHT_WIDTH,
        field: 'offStdRightWidth',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.ITEM_DESC,
        field: 'itemDesc',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.GATE_CLOSING_DATE_TIME,
        field: 'gateClosingDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 230,
    },
    {
        headerName: CONTAINER_CONSTANT.CUSTOMER_SIZE_TYPE,
        field: 'customerSizeType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.MARSHAL_FROM,
        field: 'marshalFrom',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.MARSHAL_TO,
        field: 'marshalTo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.OVER_TIME_IND,
        field: 'overtimeInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.OVER_DIMENSION_IND,
        field: 'overDimensionInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.OVER_WEIGHT_IND,
        field: 'overWeightInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.EMPTY_IND,
        field: 'emptyIndicator',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.EQUIPMENT_CODE_IND,
        field: 'equipmentCodeInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.PARTNER_CODE,
        field: 'partnerCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.CNTR_TSP_STATUS,
        field: 'cntrTspStatus',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CONTAINER_CONSTANT.OPERATION_TYPE,
        field: 'operationType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.OUT_TML_IND,
        field: 'outTerminalInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.OUT_TML_DATE_TIME,
        field: 'outTmlDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: '230px'
    },
    {
        headerName: CONTAINER_CONSTANT.OFF_STD_MEASUREMENT_MODE,
        field: 'offStdMeasurementMode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 270,
    },
    {
        headerName: CONTAINER_CONSTANT.IGNORE_CNTR_STATUS_VALIDATION,
        field: 'ignoreCntrStatusValidation',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 280,
    },
    {
        headerName: CONTAINER_CONSTANT.COKD_OPS_DATE_TIME,
        field: 'cokdOpsDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.COKD_STATUS,
        field: 'cokdStatus',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.COMMON_AC_TYPE,
        field: 'commonAcType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.COMMON_AC_FIX_QTY,
        field: 'cmAcFixQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.CM_AC_P_RATE_PERCENTAGE,
        field: 'cmAcPRatePercentage',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: CONTAINER_CONSTANT.CM_AC_P_RATE,
        field: 'chargeShareType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.RE_DIRECT_FROM_TML,
        field: 'reDirectFromTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.LEG_SPECIAL_HANDLING_CODE,
        field: 'legSpHandlingCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.LEG_SPECIAL_HANDLING_IND,
        field: 'legSpHandlingInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.REBILL_FROM,
        field: 'rebillFrom',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.BERTHING_TML,
        field: 'berthingTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    /*{
        headerName: CONTAINER_CONSTANT.FROM_DATE,
        field: 'fromDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "date",
    },
    {
        headerName: CONTAINER_CONSTANT.TO_DATE,
        field: 'toDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "date",
    },*/
    {
        headerName: CONTAINER_CONSTANT.CHARGING_FROM_DATE,
        field: 'fromDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGING_TO_DATE,
        field: 'toDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.NO_OF_QUAY_CRANE,
        field: 'noOfQuayCrane',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 190,
    },
    {
        headerName: CONTAINER_CONSTANT.SHIFT_CODE_OPS,
        field: 'shiftCodeOps',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 170,
    },
    {
        headerName: CONTAINER_CONSTANT.GANG_ID,
        field: 'gangId',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.INTENSIVE_PERCENTAGE,
        field: 'intensivePercentage',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.OPERATION_START_DATE_TIME,
        field: 'operationStartDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.OPERATION_END_DATE_TIME,
        field: 'operationEndDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.DELAY_REASON_CODE,
        field: 'delayReasonCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.DELAY_REMARKS,
        field: 'delayRemarks',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.LA_GRACE_MINS,
        field: 'laGraceMins',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.QUAY_CRANE_ID,
        field: 'quayCraneId',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.VOY_EQUIPMENT_HANDLING_CODE,
        field: 'voyEquipmentHandlingCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 280,
    },
    {
        headerName: CONTAINER_CONSTANT.OWNER,
        field: 'owner1',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.ETD,
        field: 'etd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.ETB,
        field: 'etb',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.INTENSIVE_PERCENTAGE,
        field: 'chargePercentage',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.SO_NO,
        field: 'soNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGE_CODE,
        field: 'chargeCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.WAGON_NO,
        field: 'wagonNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.TRAIN_ATB,
        field: 'trainAtb',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.TRAIN_ATD,
        field: 'trainAtd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.DRIVER_ID,
        field: 'driverId',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.DRIVER_NAME,
        field: 'driverName',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.GATE_OUT_DATE_TIME,
        field: 'gateOutDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.STORAGE_DAY_NUM,
        field: 'storageDayNum',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: CONTAINER_CONSTANT.STORAGE_MODE,
        field: 'storageMode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.EXPIRY_DATE,
        field: 'expiryDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "date",
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.IN_YARD_DAY,
        field: 'inYardPeriod',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_1,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SECOND_TIER_FSP,
        field: 'secondTierFsp',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.ADJ_CHARGE_QTY,
        field: 'adjChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 110,
    },
    {
        headerName: CONTAINER_CONSTANT.CALENDAR_ADJ_CHARGE_QTY,
        field: 'calendarAdjChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 240,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: CONTAINER_CONSTANT.MANUAL_ADJ_CHARGE_QTY,
        field: 'manualAdjChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: CONTAINER_CONSTANT.MANUAL_ADJ_CHARGE_UOM,
        field: 'manualAdjChargeQtyUom',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.CASH_OT_DAYS,
        field: 'cashChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.OT_DAYS,
        field: 'chargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGE_QTY_UOM,
        field: 'chargeQtyUom',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.POOL_UOM,
        field: 'poolQtyUom',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.CASH_AMOUNT,
        field: 'cashAmount',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.CASH_RECEIPT_NO,
        field: 'cashReceiptNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.WAIVER_NO,
        field: 'waiverNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.FIRST_TIER_FSP,
        field: 'firstTierFsp',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.STORAGE_CHARGE_QTY,
        field: 'storageChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: CONTAINER_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "date",
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.ALLIANCE,
        field: 'alliance',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.MASTER,
        field: 'master',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.CALCULATE_BY,
        field: 'calculateBy',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.QTY_20,
        field: 'qty20',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.QTY_40,
        field: 'qty40',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.QTY_45,
        field: 'qty45',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.QTY_48,
        field: 'qty48',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: CONTAINER_CONSTANT.QTY_53,
        field: 'qty53',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    // {
    //     headerName: CONTAINER_CONSTANT.TEU_QTY,
    //     field: 'teuQty',
    //     enableRowGroup: false,
    //     enablePivot: true,
    //     enableValue: true,
    //     filter: false,
    // },
    {
        headerName: CONTAINER_CONSTANT.FREE_POOL1,
        field: 'free1ChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.FREE_POOL2,
        field: 'free2ChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.TOTAL_IN_TEU,
        field: 'totalInTeu',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.TOTAL_IN_ITEM,
        field: 'totalInItem',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SUPP_POOL1,
        field: 'pool1ChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SUPP_POOL2,
        field: 'pool2ChargeQty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.EXCESS_POOL_IN_TEU,
        field: 'excessPoolInTeu',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 190,
    },
    {
        headerName: CONTAINER_CONSTANT.EXCESS_POOL_IN_ITEM,
        field: 'excessPoolInItem',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 190,
    },
    // {
    //     headerName: CONTAINER_CONSTANT.EXCESS_POOL,
    //     field: 'excessChargeQty',
    //     enableRowGroup: false,
    //     enablePivot: true,
    //     enableValue: true,
    //     filter: false,
    // },
    {
        headerName: CONTAINER_CONSTANT.RECEIVAL_CUTOFF_TIME,
        field: 'obCntrCloseDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.ACCOUNT,
        field: 'account',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.CHANGE_DATE_TIME,
        field: 'changeDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
    },
    {
        headerName: CONTAINER_CONSTANT.FIRST_DISCHARGE_DATE_TIME,
        field: 'firstDischargeDateTime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 240,
    },
    {
        headerName: CONTAINER_CONSTANT.LAST_DISCHARGE_DATE_TIME,
        field: 'lastDischargeDateTime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 240,
    },
    {
        headerName: CONTAINER_CONSTANT.FIRST_LOADING_DATE_TIME,
        field: 'firstLoadingDateTime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 240,
    },
    {
        headerName: CONTAINER_CONSTANT.LAST_LOADING_DATE_TIME,
        field: 'lastLoadingDateTime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 240,
    },
    {
        headerName: CONTAINER_CONSTANT.REEFER_HANDLING_TYPE,
        field: 'reeferHandlingType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.CREATE_DATE_TIME,
        field: 'createDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.REQUEST_DATE_TIME,
        field: 'requestDateTime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 190,
    },
    {
        headerName: CONTAINER_CONSTANT.PT_PLUG_ON_DATE_TIME,
        field: 'ptPlugOnDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.PT_PLUG_OFF_DATE_TIME,
        field: 'ptPlugOffDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.PC_PLUG_ON_DATE_TIME,
        field: 'pcPlugOnDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 260,
    },
    {
        headerName: CONTAINER_CONSTANT.PC_PLUG_OFF_DATE_TIME,
        field: 'pcPlugOffDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 260,
    },
    {
        headerName: CONTAINER_CONSTANT.TELEX_NO,
        field: 'telexNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.AGENT_CODE,
        field: 'agentCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_TYPE,
        field: 'vesselType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.MARSHALLING_TYPE,
        field: 'marshallingType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.CARGO_TYPE,
        field: 'cargoType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.PALLETIZED_INDICATOR,
        field: 'palletizedIndicator',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_ETB,
        field: 'otherEtb',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_OPERATING_TML,
        field: 'otherOperatingTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_INFO,
        field: 'vesselInfo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_SERVICE_CODE,
        field: 'otherServiceCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_CHARGE_ON_COMPANY,
        field: 'otherChargeOnCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 270,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_SPECIAL_IND,
        field: 'otherSpecialInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_DIRECT_IND,
        field: 'otherDirectInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_ETD,
        field: 'otherEtd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_FIRST_DISCHARG_EDATE_TIME,
        field: 'otherFirstDischargeDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 290,
    },
    {
        headerName: CONTAINER_CONSTANT.OTHER_LAST_DISCHARGE_DATE_TIME,
        field: 'otherLastDischargeDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 280,
    },
    {
        headerName: CONTAINER_CONSTANT.MARSHALLING_GROUND_DATE_TIME,
        field: 'marshallingGroundDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: "dateTime",
        width: 280,
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGE_TO_ACCOUNT_CODE,
        field: 'chargeToAccountCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 240,
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    cellRenderers['dtlState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    externalFnctions['dtlState'] = calculateStateColor;
    externalFnctions['accountTmlReasonCode,reasonCode'] = calcReasonCode;

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}

export const calcReasonCode = (fieldName:string, row:any) => {
    let result:{[key:string]:string} = {};
    if (fieldName && row) {
        const fieldNameList:string[] = fieldName.split(",");

        let finalFileName = "temp" + fieldNameList[0];
        let value:string | null = null;
        for (let i = 0; i < fieldNameList.length; i++) {
            value = row[fieldNameList[i]];
            if (value) {
                break;
            }
        }
        result = {[`${finalFileName}`]: value??""};
    }
    return result;
}